import type { ErrorResponse } from '@remix-run/node';
import { isRouteErrorResponse } from '@remix-run/react';
import type { ReactNode } from 'react';

import { Translation } from 'react-i18next';
import { WretchError } from 'wretch/resolver';

import { i18n_NS } from '@/i18n';

const hasHTTPErrorCode = (httpCode: number) => (e: ErrorResponse) => e.status === httpCode;
const isUnauthorizedError = hasHTTPErrorCode(401);
const isForbiddenError = hasHTTPErrorCode(403);
const isNotFoundError = hasHTTPErrorCode(404);

export const isWretchError = (e: unknown): e is WretchError => e instanceof WretchError;

export const ERROR_TYPES = {
  ROUTE_ERROR_RESPONSE: 'ROUTE_ERROR_RESPONSE',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
  WRETCH_ERROR: 'WRETCH_ERROR',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
} as const;

export const parseError = (error: unknown) => {
  // check route response error
  // e: ErrorResponse
  if (isRouteErrorResponse(error)) {
    // 4xx
    if (isUnauthorizedError(error)) {
      return { type: ERROR_TYPES.UNAUTHORIZED, error };
    }
    if (isForbiddenError(error)) {
      return { type: ERROR_TYPES.FORBIDDEN, error };
    }
    if (isNotFoundError(error)) {
      return { type: ERROR_TYPES.NOT_FOUND, error };
    }

    return { type: ERROR_TYPES.ROUTE_ERROR_RESPONSE, error };
  }

  // check request error
  // e: WretchError
  if (isWretchError(error)) {
    return { type: ERROR_TYPES.WRETCH_ERROR, error };
  }

  return { type: ERROR_TYPES.UNEXPECTED_ERROR, error };
};

export type AppError = ReturnType<typeof parseError>;

export const getErrorTranslation = (e: unknown): ReactNode => {
  const parsedError = parseError(e);
  const ns = i18n_NS.DEFAULT;
  const keyPrefix = 'appErrors';

  switch (parsedError.type) {
    case ERROR_TYPES.UNAUTHORIZED:
      return (
        <Translation ns={ns} keyPrefix={keyPrefix}>
          {(t) => t('unathorized')}
        </Translation>
      );
    case ERROR_TYPES.FORBIDDEN:
      return (
        <Translation ns={ns} keyPrefix={keyPrefix}>
          {(t) => t('forbidden')}
        </Translation>
      );
    case ERROR_TYPES.NOT_FOUND:
      return (
        <Translation ns={ns} keyPrefix={keyPrefix}>
          {(t) => t('notFound')}
        </Translation>
      );

    default:
      return (
        <Translation ns={ns} keyPrefix={keyPrefix}>
          {(t) => t('default')}
        </Translation>
      );
  }
};

export const logErrorToConsole = (error: unknown) => {
  if (isWretchError(error)) {
    const { url, status, text } = error;
    console.error(status, url, text);
    return;
  }

  console.error(error);
};
