import { Link as RouterLink } from '@remix-run/react';
import { forwardRef } from 'react';

import { type ButtonProps, Button } from '@mantine/core';

interface LinkButtonProps extends ButtonProps, Omit<React.ComponentPropsWithoutRef<typeof RouterLink>, keyof ButtonProps> {}

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => {
  const { children, ...restProps } = props;
  return (
    <Button ref={ref} component={RouterLink} {...restProps}>
      {children}
    </Button>
  );
});
LinkButton.displayName = 'LinkButton';
