import { useRouteError } from '@remix-run/react';

import { Container, Grid, Image, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '@/components/LinkButton';
import { i18n_NS } from '@/i18n';
import { ERROR_TYPES, parseError } from '@/utils/errors';

import img404 from './img404.svg';
import img500 from './img500.svg';

export const NotFound = (): JSX.Element => {
  const { t } = useTranslation(i18n_NS.DEFAULT, { keyPrefix: 'notFoundRoute' });

  return (
    <Container pt={80}>
      <Grid gutter='xl'>
        <Grid.Col span='content'>
          <Image src={img404} alt='' h={200} draggable='false' />
        </Grid.Col>
        <Grid.Col span='auto'>
          <Title>{t('title')}</Title>
          <Text c='dimmed' size='lg' mt='md'>
            {t('description')}
          </Text>
          <LinkButton to='/' variant='outline' size='md' mt='xl'>
            {t('returnBtn')}
          </LinkButton>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export const UnexpectedError = (): JSX.Element => {
  const { t } = useTranslation(i18n_NS.DEFAULT, { keyPrefix: 'unexpectedErrorRoute' });

  return (
    <Container pt={80}>
      <Grid gutter='xl'>
        <Grid.Col span='content'>
          <Image src={img500} alt='' h={200} draggable='false' />
        </Grid.Col>
        <Grid.Col span='auto'>
          <Title>{t('title')}</Title>
          <Text c='dimmed' size='lg' mt='md'>
            {t('description')}
          </Text>
          <LinkButton to='/' reloadDocument variant='outline' size='md' mt='xl'>
            {t('returnBtn')}
          </LinkButton>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export const GeneralErrorBoundary = (): JSX.Element => {
  const error = useRouteError();
  const parsedError = parseError(error);

  if (typeof document !== 'undefined') {
    console.error(error);
  }

  switch (parsedError.type) {
    case ERROR_TYPES.NOT_FOUND: {
      return <NotFound />;
    }

    default: {
      return <UnexpectedError />;
    }
  }
};
